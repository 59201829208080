import React from 'react'
import { Container, Nav, Navbar, NavDropdown, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { useLogoutMutation } from '../store/api/usersApi';
import { STATISTIC_ROUTE, IQVIA_DETAILS_ROUTE, FLOWS_ROUTE, IMPORTS_MANAGEMENT_ROUTE, USERS_ROUTE, IMPORTS_SALES_ROUTE } from '../utils/contsants'
import { useAuth } from './../hooks/userAuth';
import { ROLES_ADMIN, IMPORTS_CHARGEBACK_ROUTE } from './../utils/contsants';

const PageHeader = () => {

    const auth = useAuth();
    const [logout, { isLoading, error }] = useLogoutMutation();

    const onLogoutClick = async () => {
        await logout();
    }

    return (
        <div className='page-header'>
            <Navbar bg="light" expand="lg">
                <Container>
                    <LinkContainer to={STATISTIC_ROUTE}>
                        <Navbar.Brand href="#home">
                            <Image src='/static/img/logo.svg' style={{maxWidth:'100px'}}/>
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto w-100 justify-content-between">
                            <LinkContainer to={STATISTIC_ROUTE}>
                                <Nav.Link>Statistic</Nav.Link>
                            </LinkContainer>
                            {auth.user && auth.user.role === ROLES_ADMIN && 
                                <>
                                    <LinkContainer to={IQVIA_DETAILS_ROUTE}>
                                        <Nav.Link>IQVIA Details</Nav.Link>
                                    </LinkContainer>
                                    {/* <LinkContainer to={FLOWS_ROUTE}>
                                        <Nav.Link>Flows</Nav.Link>
                                    </LinkContainer>        */}
                                </>
                            }

                            <NavDropdown title="Import Data" id="imports-nav-dropdown">
                                <NavDropdown.Item href={IMPORTS_MANAGEMENT_ROUTE}>
                                    <LinkContainer to={IMPORTS_MANAGEMENT_ROUTE}>
                                        <Nav.Link as="div">Customers data</Nav.Link>
                                    </LinkContainer>
                                </NavDropdown.Item>
                                <NavDropdown.Item href={IMPORTS_SALES_ROUTE}>
                                    <LinkContainer to={IMPORTS_SALES_ROUTE}>
                                        <Nav.Link as="div">Sales</Nav.Link>
                                    </LinkContainer>
                                </NavDropdown.Item>
                                <NavDropdown.Item href={IMPORTS_CHARGEBACK_ROUTE}>
                                    <LinkContainer to={IMPORTS_CHARGEBACK_ROUTE}>
                                        <Nav.Link as="div">Chargebacks</Nav.Link>
                                    </LinkContainer>
                                </NavDropdown.Item>
                            </NavDropdown>
                                                 
                            {/* <NavDropdown title="Management" id="basic-nav-dropdown">
                                <NavDropdown.Item href={IMPORTS_MANAGEMENT_ROUTE}>
                                    <LinkContainer to={IMPORTS_MANAGEMENT_ROUTE}>
                                        <Nav.Link as="div">Imports</Nav.Link>
                                    </LinkContainer>
                                </NavDropdown.Item>                       
                            </NavDropdown>
                            <NavDropdown title="Chargebacks" id="chargebacks-nav-dropdown">
                                <NavDropdown.Item href={IMPORTS_CHARGEBACK_ROUTE}>
                                    <LinkContainer to={IMPORTS_CHARGEBACK_ROUTE}>
                                        <Nav.Link as="div">Import data</Nav.Link>
                                    </LinkContainer>
                                </NavDropdown.Item>
                            </NavDropdown> */}

                            {auth.user && auth.user.role === ROLES_ADMIN && 
                                <NavDropdown title="Dashboard" id="ds-nav-dropdown" className="justify-content-end">
                                    <NavDropdown.Item href={USERS_ROUTE}>
                                        <LinkContainer to={USERS_ROUTE}>
                                            <Nav.Link as="div">Users</Nav.Link>
                                        </LinkContainer>
                                    </NavDropdown.Item>
                                </NavDropdown>   
                            }
                            
                            {auth.user && 
                                <a className="nav-link" style={{cursor: 'pointer'}} onClick={onLogoutClick}>Sign Out</a>
                            }

                        </Nav>
                    </Navbar.Collapse>
                </Container>
                
            </Navbar>
        </div>
    )
}

export default PageHeader
