import React from 'react';
import { Card, Container } from 'react-bootstrap';
import LoginForm from '../forms/LoginForm';

const LoginPage = () => {
    return (
        <Container className='d-flex justify-content-center align-items-center' style={{height: window.innerHeight}}>
            <Card style={{width: '600px'}} className='p-5'>
                <h2 className='m-auto'>Login</h2>
                <LoginForm/>
            </Card>
        </Container>
    );
};

export default LoginPage;