import React, {useEffect, useMemo} from 'react'
import { Button, Form, FormControl, Modal, Spinner } from 'react-bootstrap'
import { updateUserValidationSchema } from '../../../utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IUser } from '../../../models/IUser';
import { useUpdateUserMutation } from '../../../store/api/usersApi';
import { UserRole } from '../../../models/enums/UserRole';
import { FlowType } from '../../../models/enums/FlowType';

type UpdateUserModalProps = {
    user: IUser;
    show: boolean;
    onHide: () => void;
    onSuccess: () => void;
}

const defaultErrorMsg = 'Oops. Something went wrong. Please, try again later. ';

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({user, show, onHide, onSuccess}) => {

    const [updateUser, {isLoading, error}] = useUpdateUserMutation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IUser>({
        defaultValues: useMemo(() => {
            return user;
          }, [user]),
        resolver: yupResolver(updateUserValidationSchema)
    });

    useEffect(() => {
        reset(user);
      }, [user]);

    const onSubmit = async (data: IUser) => {

        // setShowAlert(null)
        console.log('Submit data', data)

        try{
            const userData = await updateUser(data);
            console.log('User Data', userData);
            
            if('error' in userData){
                console.log(userData.error);
                const err = userData.error as any;
                const errorMsg = err.data?.message || defaultErrorMsg;
                // setShowAlert(errorMsg)
            }
            else{
                reset()
                onSuccess()
            }
        }
        catch(err){
            console.log('alert')
            // setShowAlert(defaultErrorMsg)
            console.log('error in catch: ', err);
        }
    }

    return (
        <Modal
            
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update user
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>

                    <FormControl
                    {...register('email')}
                    className={`mt-3 ${errors.email ? 'is-invalid' : ''}`}
                    placeholder='Email'
                    />
                    <div className="invalid-feedback align-self-start">{errors.email?.message}</div>

                    <Form.Select
                        {...register('role')}
                        aria-label="Role select"
                        className={`mt-3 ${errors.role ? 'is-invalid' : ''}`}>
                        <option value="">Choose Role</option>
                        <option value={UserRole.USER}>USER</option>
                        <option value={UserRole.ADMIN}>ADMIN</option>
                    </Form.Select>
                    <div className="invalid-feedback align-self-start">{errors.role?.message}</div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={onHide}>Close</Button>
                <Button variant='outline-success' onClick={handleSubmit(onSubmit)}>
                    {isLoading && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"/>}
                    <span className="sr-only">Update</span>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateUserModal
