import axios from "axios";
import { IIqviaDetail } from "../../models/IIqviaDetail";
import { AppDispatch } from "../store";
import {iqviaDetailSlice} from './IqviaDetailSlice'
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from '../../config';

const apiURL = config.API_URL;

// export const fetchIqviaDetailsOld = () => async (dispatch: AppDispatch) => {
//     try{
//         dispatch(iqviaDetailSlice.actions.detailsFetching())
//         const response = await axios.get<IIqviaDetail[]>(apiURL + '/iqvia-details');
//         dispatch(iqviaDetailSlice.actions.detailsFetchingSuccess(response.data))

//     }
//     catch(err: any){
//         console.log(err);
//         dispatch(iqviaDetailSlice.actions.detailsFetchingError(err.message))
//     }
// }

export const fetchIqviaDetails = createAsyncThunk(
    'iqviaDetails/fetchAll',
    async(_: void, thunkAPI) => {
        try{
            const response = await axios.get<IIqviaDetail[]>(apiURL + '/iqvia-details');
            return response.data;
        }
        catch(err: any){
            return thunkAPI.rejectWithValue('Error while fetching: '+ err.message)
        }
    }
)