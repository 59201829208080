import React from 'react'
import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FlowStatus } from '../../models/enums/FlowStatus';
import { IFlow } from '../../models/IFlow';

type FlowListItemProps = {
    flow: IFlow,
    onFlowEdit: (flow: IFlow) => void;
    onFlowDelete: (id: number) => void;
}

const FlowListItem: React.FC<FlowListItemProps> = ({flow, onFlowEdit, onFlowDelete}) => {

    const getBadgeVariant = (status: FlowStatus) => {
        switch (status) {
            case FlowStatus.OK:
                return "success"
            case FlowStatus.ACTIVE:
                return "primary"
            default:
                return "success"
        }
    }

    return (
        <tr>
            <td>{flow.id}</td>
            <td>{flow.title}</td>
            <td>{flow.type}</td>
            <td>
                <Badge pill bg={getBadgeVariant(flow.status)}>
                    {flow.status}
                </Badge>
            </td>
            <td>
                <Button variant="secondary" size="sm" className='me-1'>View</Button>
                <Button variant="secondary" size="sm" className='me-1' onClick={() => onFlowEdit(flow)}>Edit</Button>
                <Button variant="danger" size="sm" onClick={()=>onFlowDelete(flow.id)} >Delete</Button>
            </td>
        </tr>
        
    )
}

export default FlowListItem
