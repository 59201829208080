import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { IPieStatistic } from '../../models/AccountsStatistic';
import { getPieChartData } from './../../utils/functions';
import { useGetAccountsBySourceQuery } from '../../store/api/statisticApi';

// ChartJS.register(ArcElement, Tooltip, Legend);

type StatisticChartProps = {
    title: string
    chartLabel: string
    data?: IPieStatistic | undefined
    isLoading: boolean
    isError: boolean
}


const StatisticChart: React.FC<StatisticChartProps> = ({title, chartLabel, data, isLoading, isError}) => {

    return (
        <div className='stat-chart'>
            <h5 className='chart-title'>{title}</h5>
            <div className='chart-body'>
                {isLoading && <div>Loading...</div>}
                {!isLoading && !isError && <Pie data={getPieChartData(chartLabel, data)} />}
            </div>
        </div>
    )
}

export default StatisticChart
