import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { IChargebackYearStatistic } from './../../models/chargeback/IChargebackYearStatistic';
import { IChargebackFieldStatistic } from './../../models/chargeback/IChargebackFieldStatistic';
const { format } = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

type ChargebacksFieldStatisticProps = {
    fieldName: string,
    valueName: string,
    data: IChargebackFieldStatistic[],
}

const ChargebacksFieldStatistic: React.FC<ChargebacksFieldStatisticProps> = ({ fieldName, valueName, data }) => {

    const [items, setItems] = useState<IChargebackFieldStatistic[]>(data)
    const [totalSum, setTotalSum] = useState(0)


    useEffect(() => {
        recalculateTotalSum();
    
    }, [items])
    

    const onItemChange = (title: string, e: React.ChangeEvent<HTMLInputElement>) => {
        
        const updatedItems = items.map(item => {
            const newItem = Object.assign({}, item);
            if (newItem.title === title){
                newItem.active = e.target.checked;
            }
            return newItem;
        })

        setItems(updatedItems);
    }

    const recalculateTotalSum = () => {
       const sum = items.reduce((total, item) => {
            if (item.active) {
                return total + item.value;
            }
            return total;
        }, 0);

        setTotalSum(sum)
    }

    const getPercentOfBusiness = (item: IChargebackFieldStatistic) => {
        if(!item.active){
            return '0'
        }

        return (item.value / totalSum * 100).toFixed(2);
    }

    return (

        <Table striped bordered hover style={{ fontSize: '14px' }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{fieldName}</th>
                    <th>{valueName}</th>
                    <th>Percent of Business</th>
                </tr>
            </thead>
            <tbody>
                {items.map(item => {
                    return <tr key={item.title}>
                        <td>
                            <input type="checkbox" onChange={e => onItemChange(item.title, e)} checked={item.active} />
                        </td>
                        <td>{item.title}</td>
                        <td>{item.value}</td>
                        <td>{getPercentOfBusiness(item)}%</td>
                    </tr>
                })}
                <tr>
                    <td></td>
                    <td>Total</td>
                    <td>{totalSum}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default ChargebacksFieldStatistic;