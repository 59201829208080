import React from 'react'
import { Container } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';


type InternalPageProps = {
    children?: React.ReactNode
}

const InternalPage: React.FC<InternalPageProps> = ({children}) => {
    return (
        <Container>
            {/* <div className='app-header'></div> */}
            <PageHeader/>
            <div className='internal-page'>
                {/* <div className='sidebar'>Sidebar</div> */}
                <div className='paige-content py-3'>
                    {children}
                </div>
            </div>
        </Container>
    )
}

export default InternalPage
