import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import InternalPage from '../InternalPage';
import FlowList from '../../components/flow/FlowList';
import { IFlow } from './../../models/IFlow';
import { useDeleteFlowMutation, useGetAllFlowsQuery } from '../../store/api/flowApi';
import CreateFlow from './../../components/modals/CreateFlow';
import UpdateFlow from './../../components/modals/UpdateFlow';

const FlowsPage = () => {

    const [createFlowVisible, setCreateFlowVisible] = useState<boolean>(false)
    const [updateFlowVisible, setUpdateFlowVisible] = useState<boolean>(false)
    const [editFlow, setEditFlow] = useState<IFlow | null>(null)

    const {data, isError, isFetching, refetch} = useGetAllFlowsQuery(undefined,{
        refetchOnMountOrArgChange: true,
    });
    const [deleteFlow, {isLoading, error}]  = useDeleteFlowMutation()
    
    const onEditFlowSuccess = () => {
        setCreateFlowVisible(false);
        setUpdateFlowVisible(false);
        refetch()
    }

    const onFlowEdit = (flow: IFlow) => {
        setEditFlow(flow);
        setUpdateFlowVisible(true);
    }

    const onFlowDelete = async(id: number) => {
        await deleteFlow(id)
        refetch()
    }

    return (
        <InternalPage>
            <h1>
                Flows
            </h1>
            <div className='main-content'>
                <Card className='w-100 p-3'>
                    <div className="mb-2">
                        <Button variant="success" style={{float: 'right'}} onClick={() => setCreateFlowVisible(true)}>Create new</Button>
                    </div>
                    
                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError &&<FlowList flows={data} onFlowEdit={onFlowEdit} onFlowDelete={onFlowDelete}/>}
                </Card>
               
            </div>
            <CreateFlow show={createFlowVisible} onSuccess={onEditFlowSuccess} onHide={() => setCreateFlowVisible(false)}/>
            {editFlow && <UpdateFlow show={updateFlowVisible} onSuccess={onEditFlowSuccess} onHide={() => setUpdateFlowVisible(false)} flow={editFlow}/>}
        </InternalPage>
        
    );
};

export default FlowsPage;