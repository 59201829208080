import React from 'react'
import Table from 'react-bootstrap/Table';
import { IFlow } from '../../models/IFlow';
import FlowListItem from './FlowListItem';

type FlowListProps = {
    flows: IFlow[];
    onFlowEdit: (flow: IFlow) => void;
    onFlowDelete: (id: number) => void;
}

const FlowList: React.FC<FlowListProps> = ({flows, onFlowEdit, onFlowDelete}) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>#</th>
                </tr>
            </thead>
            <tbody>
                {flows.map(flow => <FlowListItem key={flow.id} flow={flow} onFlowEdit={onFlowEdit} onFlowDelete={onFlowDelete} />)}
            </tbody>
        </Table>
        
    )
}

export default FlowList
