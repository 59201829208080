import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouter from './components/AppRouter';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { useGetCurrentQuery } from './store/api/usersApi';
import { fetchIqviaDetails } from './store/features/ActionCreators';
import { iqviaDetailSlice } from './store/features/IqviaDetailSlice';

function App() {

  // const {isLoading, error, details} = useAppSelector(state => state.iqviaDetailsReducer);
  // const dispatch = useAppDispatch();
  
  // useEffect(() => {
  //   dispatch(fetchIqviaDetails())
  // }, [])
  
  const{isLoading, error, data: user} = useGetCurrentQuery()

  if(isLoading){
    return <div>Loading...</div>
  }
  else return (
    <BrowserRouter>
    <div className="App">
      <AppRouter/>
    </div>
    </BrowserRouter>

  );
}

export default App;
