import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { IPieStatistic } from '../../models/AccountsStatistic';
import { getPieChartData } from './../../utils/functions';
import { useGetAccountsByCountryQuery, useGetAccountsBySourceQuery, useGetOnekeyAccountsByChildQuery, useGetOnekeyAccountsQuery } from '../../store/api/statisticApi';
import StatisticChart from './StatisticChart';

ChartJS.register(ArcElement, Tooltip, Legend);

const AccountsStatistic = () => {

    const { data: accountsSourcesStat, isLoading: accountsSourcesLoading, isError: accountsSourcesError } = useGetAccountsBySourceQuery(undefined, {
        refetchOnMountOrArgChange: true,
    })
    const { data: onekeyAccountsStat, isLoading: onekeyAccountsLoading, isError: onekeyAccountsError } = useGetOnekeyAccountsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    })
    const { data: accountsCountryStat, isLoading: accountsCountryLoading, isError: accountsCountryError } = useGetAccountsByCountryQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const { data: onekeyByChildStat, isLoading: onekeyByChildLoading, isError: onekeyByChildError } = useGetOnekeyAccountsByChildQuery(undefined, {
        refetchOnMountOrArgChange: true,
    })

    return (
        <div className='accounts-statistic'>
            <Card className='w-100 p-3' >
                <Card.Title>Accounts Statistic</Card.Title>
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <StatisticChart title='All accounts' chartLabel='# of accounts' data={accountsSourcesStat} isLoading={accountsSourcesLoading} isError={accountsSourcesError}/>
                        </Col>
                        <Col md={3}>
                            <StatisticChart title='OneKey accounts' chartLabel='# of accounts' data={onekeyAccountsStat} isLoading={onekeyAccountsLoading} isError={onekeyAccountsError}/>
                        </Col>
                        <Col md={3}>
                            <StatisticChart title='Accounts by country' chartLabel='# of accounts' data={accountsCountryStat} isLoading={accountsCountryLoading} isError={accountsCountryError}/>
                        </Col>
                        <Col md={3}>
                            <StatisticChart title='OneKey accounts by tax' chartLabel='# of accounts' data={onekeyByChildStat} isLoading={onekeyByChildLoading} isError={onekeyByChildError}/>
                        </Col>
                    </Row>
                    
                </Card.Body>
            </Card>
        </div>
    )
}

export default AccountsStatistic
