import React from 'react';
import { Card } from 'react-bootstrap';
import AccountsStatistic from '../components/statistic/AccountsStatistic';
import InternalPage from './InternalPage';

const StatisticPage = () => {
    return (
        <InternalPage>
            <h1>
                Statistic Page
            </h1>
            <div className='main-contant'>
                <AccountsStatistic/>
            </div>
        </InternalPage>
        
    );
};

export default StatisticPage;