import { authSlice } from './features/AuthSlice';
import { api } from './api/api'
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import iqviaDetailsReducer from './features/IqviaDetailSlice';


const rootReducer = combineReducers({
    iqviaDetailsReducer: iqviaDetailsReducer,
    [api.reducerPath]: api.reducer,
    authReducer: authSlice.reducer,
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefeultMiddleware) => getDefeultMiddleware().concat(api.middleware)
    })
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];