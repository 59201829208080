import React from 'react';
import { Button } from 'react-bootstrap';
import { IUser } from '../../models/IUser';

type UserListItemProps = {
    user: IUser;
    onUserEdit: (user: IUser) => void;
    onUserDelete: (user: IUser) => void;
}

const UsersListItem: React.FC<UserListItemProps> = ({user, onUserEdit, onUserDelete}) => {
    return (
        <tr>
            <td>{user.id}</td>
            <td>{user.email}</td>
            <td>{user.role}</td>
            <td>
                <Button variant="secondary" size="sm" className='me-1' onClick={() => onUserEdit(user)}>Edit</Button>
                <Button variant="danger" size="sm" onClick={() => onUserDelete(user)} >Delete</Button>
            </td>
        </tr>

    )
};

export default UsersListItem;