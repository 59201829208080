import { IPieStatistic } from './../../models/AccountsStatistic';
import { retry } from '@reduxjs/toolkit/query/react'
import { api } from './api'

export const statisticApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAccountsBySource: build.query<IPieStatistic, void>({
            query: () =>  ({ url: '/statistic/accounts-by-source' }),
        }),
        getOnekeyAccounts: build.query<IPieStatistic, void>({
            query: () =>  ({ url: '/statistic/onekey-accounts' }),
        }),
        getAccountsByCountry: build.query<IPieStatistic, void>({
            query: () =>  ({ url: '/statistic/accounts-by-country' }),
        }),
        getOnekeyAccountsByChild: build.query<IPieStatistic, void>({
            query: () =>  ({ url: '/statistic/onekey-accounts-by-child' }),
        }),
    })
})

export const {
    useGetAccountsBySourceQuery,
    useGetOnekeyAccountsQuery,
    useGetAccountsByCountryQuery,
    useGetOnekeyAccountsByChildQuery,
} = statisticApi