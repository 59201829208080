import React, {useState} from 'react';
import { Button, Card } from 'react-bootstrap';
import IqviaDetailsList from '../components/IqviaDetails/IqviaDetailsList';
import CreateIqviaDetail from '../components/modals/CreateIqviaDetail';
import UpdateIqviaDetail from '../components/modals/UpdateCreateIqviaDetail';
import { IIqviaDetail } from '../models/IIqviaDetail';
import { useDeleteDetailMutation, useGetAllDetailsQuery } from '../store/api/iqviaDetailApi';
import InternalPage from './InternalPage';

const IqviaDetailsPage = () => {

    const [createDetailVisible, setCreateDetailVisible] = useState<boolean>(false)
    const [updateDetailVisible, setUpdateDetailVisible] = useState<boolean>(false)
    const [editDetail, setEditDetail] = useState<IIqviaDetail | null>(null)

    const {data, isError, isFetching, refetch} = useGetAllDetailsQuery(undefined,{
        refetchOnMountOrArgChange: true,
    });

    const [deleteDetail, {isLoading, error}]  = useDeleteDetailMutation();
    
    const onEditDetailSuccess = () => {
        setCreateDetailVisible(false);
        setUpdateDetailVisible(false);
        // refetch()
    }

    const onDetailEdit = (detail: IIqviaDetail) => {
        console.log(detail);
        setEditDetail(detail);
        setUpdateDetailVisible(true);
    }

    const onDetailDelete = async(id: number) => {
        await deleteDetail(id)
        // refetch()
    }

    return (
        <InternalPage>
            <h1>
                Iqvia Details Page
            </h1>
            <div className='main-content'>
                <Card className='w-100 p-3'>
                    <div className="mb-2">
                        <Button variant="success" style={{float: 'right'}} onClick={() => setCreateDetailVisible(true)}>Create new</Button>
                    </div>
                    
                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError &&<IqviaDetailsList details={data} onDetailEdit={onDetailEdit} onDetailDelete={onDetailDelete}/>}
                </Card>
               
            </div>
            <CreateIqviaDetail show={createDetailVisible} onSuccess={onEditDetailSuccess} onHide={() => setCreateDetailVisible(false)}/>
            {editDetail && <UpdateIqviaDetail show={updateDetailVisible} onSuccess={onEditDetailSuccess} onHide={() => setUpdateDetailVisible(false)} detail={editDetail}/>}
        </InternalPage>

    );
};

export default IqviaDetailsPage;