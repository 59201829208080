import { IChargebackImport } from '../../models/chargeback/ChargebackImport';
import { ISalesImport } from '../../models/MassImport/ISalesImport';
// import { IMassImport } from '../../models/MassImport/IMassImport';
import { api } from './api'
import { ISalesImportListResponse, CreateSalesImportRequest, ProcessSalesImportResponse } from './types';

export const salesImportApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllSalesImports: build.query<ISalesImportListResponse, void>({
            query: () =>  ({ url: '/sales-import/all' }),
            providesTags: ['SalesImport'],
        }),
        // getMassImport: build.query<IMassImport, string|null>({
        //     query: (id: string) => ({ url: '/mass-import/'+id }),
        // }),import { IChargebackImport } from './../../models/chargeback/ChargebackImport';

        createSalesImport: build.mutation<ISalesImport, CreateSalesImportRequest>({
            query: (createInput: CreateSalesImportRequest) => {

                const formData = new FormData();
                formData.append('title', createInput.title);
                formData.append('description', createInput.description);
                formData.append('type', createInput.type);
                formData.append('updateFile', createInput.updateFile);
                formData.append('file', createInput.updateFile);

                return{
                    url: '/sales-import/create',
                    method: 'POST',
                    body: formData ,
                    credentials: 'include',
                    formData: true,
                }

            },
            invalidatesTags: ['SalesImport'],
          }),
          
        processSalesImport: build.mutation<ProcessSalesImportResponse, string>({
            query: (id: string) => ({
                url: '/sales-import/process-import',
                method: 'POST',
                body: {id},
                credentials: 'include',
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    salesImportApi.util.updateQueryData('getAllSalesImports', undefined as void, (draft) => {
                        const foundObject = draft.imports.find(obj => obj.id === id);
                        if (foundObject) {
                            foundObject.isLoading = true;
                        }  
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: ['SalesImport'],
        }),
        deleteSalesImport: build.mutation<void, string>({
            query: (id: string) => ({
                url: `/sales-import/delete/${id}`,
                method: 'POST',
                credentials: 'include',
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    salesImportApi.util.updateQueryData('getAllSalesImports', undefined as void, (draft) => {
                        const foundObject = draft.imports.find(obj => obj.id === id);
                        if (foundObject) {
                            foundObject.isDeleting = true;
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: (result, error) => error ? [] : ['SalesImport'],
        }),
    })
})

export const {
    useGetAllSalesImportsQuery,
    useCreateSalesImportMutation,
    useProcessSalesImportMutation,
    useDeleteSalesImportMutation,
} = salesImportApi