import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { IChargebackCommonStatistic } from './../../models/chargeback/IChargebackCommonStatistic';
const { format } = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

type ChargebacksCommonStatisticProps = {
    fieldName: string,
    statistic: IChargebackCommonStatistic[]
}

const ChargebacksCommonStatistic: React.FC<ChargebacksCommonStatisticProps> = ({ fieldName, statistic: items }) => {

    const getChangesPercent = (percent: number) => {
        if(percent === 0){
            return <sup> -</sup>
        }

        if(percent > 0){
            return <sup style={{ color: 'green' }}> +{percent.toFixed(2)}%</sup>
        }
        if (percent < 0) {
            return <sup style={{ color: 'red' }}> {percent.toFixed(2)}%</sup>
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>{fieldName}</th>
                                <th>2020 YTD Doses</th>
                                <th>2021 YTD Doses</th>
                                <th>2022 YTD Doses</th>
                                <th>2023 YTD Doses</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => {
                                return <tr key={item.title}>
                                    <td>{item.title}</td>
                                    <td>{item.value2020}</td>
                                    <td>
                                        {item.value2021}
                                        {getChangesPercent(item.value2021Changes)}
                                    </td>
                                    <td>
                                        {item.value2022}
                                        {getChangesPercent(item.value2022Changes)}
                                    </td>
                                    <td>
                                        {item.value2023}
                                        {getChangesPercent(item.value2023Changes)}
                                    </td>
                                </tr>
                            })}
                            {/* <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> */}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
};

export default ChargebacksCommonStatistic;