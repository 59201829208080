import { IPieStatistic } from "../models/AccountsStatistic";

export const getPieChartData = (label: string, statistic: IPieStatistic | undefined) => {
  
  if(!statistic){
    return {
      labels: [],
      datasets: []
    }
  }
  
  return {
    labels: statistic.labels,
    datasets: [
      {
        label,
        data: statistic.values,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ]
}}

export const formatDate = (dateStr: Date) => {

  const date = new Date(dateStr);
  
  if (typeof date.getMonth !== 'function'){
    return date.toLocaleString('en-US');
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}.${month}.${year}\n${hours}:${minutes}:${seconds}`;
}