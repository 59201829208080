import { IqviaDetailResponse } from './types';
import { IIqviaDetail } from './../../models/IIqviaDetail';
import { api } from './api'

export const flowApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllDetails: build.query<IIqviaDetail[], void>({
            query: () =>  ({ url: '/iqvia-details' }),
            providesTags: ['IqviaDetail'],
        }),
        createDetail: build.mutation<IqviaDetailResponse, IIqviaDetail>({
            query: (detailInput: IIqviaDetail) => ({
              url: '/iqvia-details/create',
              method: 'POST',
              body: detailInput,
              credentials: 'include',
            }),
            invalidatesTags: ['IqviaDetail']
          }),
          updateDetail: build.mutation<IqviaDetailResponse, IIqviaDetail>({
            query: (detailInput: IIqviaDetail) => ({
              url: '/iqvia-details/update',
              method: 'POST',
              body: detailInput,
              credentials: 'include',
            }),
            invalidatesTags: ['IqviaDetail']
          }),
          deleteDetail: build.mutation<void, number>({
            query: (id: number) => ({
              url: `/iqvia-details/${id}`,
              method: 'DELETE',
              credentials: 'include',
            }),
            invalidatesTags: ['IqviaDetail']
          }),
    })
})

export const {
    useGetAllDetailsQuery,
    useCreateDetailMutation,
    useUpdateDetailMutation,
    useDeleteDetailMutation,
} = flowApi