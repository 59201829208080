import React from 'react';
import { Button } from 'react-bootstrap';
import { IImportLog } from '../../models/IImportLog';


type ImportLogListItemProps = {
    importItem: IImportLog
}


const ImportLogListItem: React.FC<ImportLogListItemProps> = ({importItem}) => {
    return (
        <tr>
            <td>{importItem.id}</td>
            <td>{importItem.type}</td>
            <td>{importItem.message}</td>           
            <td>{importItem.created_at.toLocaleString()}</td>            
        </tr>
    );
};

export default ImportLogListItem;