import React from 'react';
import { Table } from 'react-bootstrap';
import { IImportLog } from '../../models/IImportLog';
import ImportLogListItem from './ImportLogListItem';

type ImportLogListProps = {
    logs: IImportLog[]
}

const ImportLogList: React.FC<ImportLogListProps> = ({logs}) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>                    
                    <th>Type</th>
                    <th>Message</th>                    
                    <th>Date</th>                    
                </tr>
            </thead>
            <tbody>
                {logs.map(logItem => <ImportLogListItem key={logItem.id} importItem={logItem} />)}
            </tbody>

        </Table>
    );
};

export default ImportLogList;