import { retry } from '@reduxjs/toolkit/query/react'
import { IUser } from '../../models/IUser';
import { api } from './api'
import { LoginRequest, UserResponse, SignUpRequest, IServerResponse } from './types';

type UsersResponse = IUser[];

export const usersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query<UsersResponse, void>({
            query: () =>  ({ url: '/user/all' }),
            providesTags: ['User'],
        }),

        login: build.mutation<UserResponse, LoginRequest>({
            query: (loginInput: LoginRequest) => ({
              url: '/user/login',
              method: 'POST',
              body: loginInput,
              credentials: 'include',
            }),
            extraOptions: {
              backoff: () => {
                // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
                retry.fail({ fake: 'error' })
              },
            },
          }),
          getCurrent: build.query<UserResponse, void>({
            query: () => ({
              url: '/user/refresh',
              method: 'GET',
              credentials: 'include',
            })
          }),
          createUser: build.mutation<UserResponse, SignUpRequest>({
            query: (userInput: SignUpRequest) => ({
              url: '/user/create',
              method: 'POST',
              body: userInput,
              credentials: 'include',
            }),
            invalidatesTags: (result, error) => error ? [] : ['User'],
          }),
          updateUser: build.mutation<UserResponse, IUser>({
            query: (userInput: IUser) => ({
              url: '/user/update',
              method: 'POST',
              body: userInput,
              credentials: 'include',
            }),
            invalidatesTags: (result, error) => error ? [] : ['User'],
          }),
          deleteUser: build.mutation<void, number>({
            query: (id: number) => ({
              url: `/user/${id}`,
              method: 'DELETE',
              credentials: 'include',
            }),
            invalidatesTags: (result, error) => error ? [] : ['User'],
          }),
        logout: build.mutation<IServerResponse, void>({
          query: () => ({
            url: '/user/logout',
            method: 'POST',
            credentials: 'include',
          }),
        }),
    })
})

export const {
    useGetUsersQuery,
    useLoginMutation,
    useGetCurrentQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useLogoutMutation,
} = usersApi