import { IChargebackImport } from '../../models/chargeback/ChargebackImport';
import { IChargebackCommonStatistic } from '../../models/chargeback/IChargebackCommonStatistic';
import { IChargebackYearStatistic } from '../../models/chargeback/IChargebackYearStatistic';
import { IPagedListParams } from '../../models/IPagedListParams';
// import { IMassImport } from '../../models/MassImport/IMassImport';
import { api } from './api'
import { IChargebackImportListResponse, CreateChargebackImportRequest, ProcessChargebackImportResponse, IServerResponse, CorrectChargebackImportValuesRequest, ICorrectedChargebackValueListResponse } from './types';

export const massImportChargebacksApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllChargebackImports: build.query<IChargebackImportListResponse, IPagedListParams>({
            query: ({
                page,
                pageSize,
                sort,
                dir,
            }) =>  ({ url: `/chargeback/all?page=${page}&pageSize=${pageSize}&sort=${sort}&dir=${dir}` }),
            providesTags: ['Chargeback'],
        }),
        // getMassImport: build.query<IMassImport, string|null>({
        //     query: (id: string) => ({ url: '/mass-import/'+id }),
        // }),import { IChargebackImport } from './../../models/chargeback/ChargebackImport';

        createChargebackImport: build.mutation<IChargebackImport, CreateChargebackImportRequest>({
            query: (createInput: CreateChargebackImportRequest) => {

                const formData = new FormData();
                formData.append('title', createInput.title);
                formData.append('description', createInput.description);
                formData.append('type', createInput.type);
                formData.append('updateFile', createInput.updateFile);
                formData.append('file', createInput.updateFile);

                return{
                    url: '/chargeback/create',
                    method: 'POST',
                    body: formData ,
                    credentials: 'include',
                    formData: true,
                }

            },
            invalidatesTags: (result, error) => error ? [] : ['Chargeback'],
          }),
          
        processChargebacksImport: build.mutation<ProcessChargebackImportResponse, string>({
            query: (id: string) => ({
                url: '/chargeback/process-chargeback',
                method: 'POST',
                body: {id},
                credentials: 'include',
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    massImportChargebacksApi.util.updateQueryData('getAllChargebackImports',{
                        page: 1,
                        pageSize: 10,
                        sort: 'created_at',
                        dir: 'desc',
                    }, (draft) => {
                        const foundObject = draft.imports.find(obj => obj.id === id);
                        if (foundObject) {
                            foundObject.isLoading = true;
                        }  
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: ['Chargeback'],
        }),
        deleteChargebacksImport: build.mutation<void, string>({
            query: (id: string) => ({
                url: `/chargeback/delete/${id}`,
                method: 'POST',
                credentials: 'include',
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    massImportChargebacksApi.util.updateQueryData('getAllChargebackImports', {
                        page: 1,
                        pageSize: 10,
                        sort: 'created_at',
                        dir: 'desc',
                    }, (draft) => {
                        const foundObject = draft.imports.find(obj => obj.id === id);
                        if (foundObject) {
                            foundObject.isDeleting = true;
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: (result, error) => error ? [] : ['Chargeback'],
        }),

        getCorrectedChargebacksImportValues: build.query<ICorrectedChargebackValueListResponse, void>({
            query: () => ({ url: '/chargeback/get-corrected-all' }),
            providesTags: ['CorrectedChargebackValue'],
        }),

        correctChargebacksImportValues: build.mutation<IServerResponse, CorrectChargebackImportValuesRequest>({
            query: (body: CorrectChargebackImportValuesRequest) => ({
                url: '/chargeback/correct-cb-values',
                method: 'POST',
                body,
                credentials: 'include',
            }),
            invalidatesTags: ['CorrectedChargebackValue'],
        }),

        getYearStatistic: build.query<IChargebackYearStatistic, number>({
            query: (year: number) => ({ url: `/chargeback/statistic?year=${year}` }),
        }),
        getCommonStatistic: build.query<IChargebackCommonStatistic[], void>({
            query: () => ({ url: `/chargeback/statistic-common` }),
        }),
    })
})

export const {
    useGetAllChargebackImportsQuery,
    useCreateChargebackImportMutation,
    useProcessChargebacksImportMutation,
    useDeleteChargebacksImportMutation,
    useGetCorrectedChargebacksImportValuesQuery,
    useCorrectChargebacksImportValuesMutation,
    useGetYearStatisticQuery,
    useGetCommonStatisticQuery,
} = massImportChargebacksApi