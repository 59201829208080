import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { IChargebackYearStatistic } from './../../models/chargeback/IChargebackYearStatistic';
import ChargebacksFieldStatistic from './ChargebacksFieldStatistic';
const { format } = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

type ChargebacksStatisticProps = {
    statistic: IChargebackYearStatistic
}

const ChargebacksStatistic: React.FC<ChargebacksStatisticProps> = ({ statistic }) => {


    let totalQtySoldSum = 0;
    let totalWacSalesSum  = 0;
    let totalContractSalesSum = 0;

    const monthRows = statistic.monthesStatistic.map(item => {
        totalQtySoldSum += item.qtySoldSum;
        totalWacSalesSum += item.wacSalesSum;
        totalContractSalesSum += item.contractSalesSum;

        return <tr key={item.month}>
            <td>{item.month}</td>
            <td>{item.title}</td>
            <td>{item.qtySoldSum}</td>
            <td>{format(item.wacSalesSum)}</td>
            <td>{format(item.contractSalesSum)}</td>
        </tr>
    })

    return (
        <div>
            <Row>
                <Col>
                    <Table striped bordered hover style={{fontSize: '14px'}}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Month</th>
                                <th>Sum of Qty Sold of Product</th>
                                <th>Sum of WAC Sales</th>
                                <th>Sum of Contract Sales</th>
                            </tr>
                        </thead>
                        <tbody>
                            {monthRows}
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td>{totalQtySoldSum}</td>
                                <td>{format(totalWacSalesSum)}</td>
                                <td>{format(totalContractSalesSum)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <ChargebacksFieldStatistic fieldName='Wholesaler Sum' valueName='Sum of Qty Sold of Product' data={statistic.wholesalerSumStatistic}/>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <ChargebacksFieldStatistic fieldName='GPO Summary' valueName='Sum of Qty Sold of Product' data={statistic.gpoSummarySumStatistic} />
                </Col>
                <Col>
                    <ChargebacksFieldStatistic fieldName='GPO Name' valueName='Sum of Qty Sold of Product' data={statistic.gpoNameSumStatistic} />
                </Col>
            </Row>
        </div>
    );
};

export default ChargebacksStatistic;