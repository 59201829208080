import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import InternalPage from './InternalPage';
import CreateMassImportModal from '../components/modals/massImport/CreateMassImportModal';
import { useCreateMassImportMutation, useGetAllMassImportsQuery, useProcessImportMutation } from '../store/api/massImportApi';
import MassImportList from '../components/massImport/MassImportList';
import MassImportDetails from '../components/massImport/MassImportDetails';
import { IImportTypeOption } from '../models/MassImport/IImportTypeOption';
import { MassImportType } from '../models/MassImport/MassImportType';

const importTypes: IImportTypeOption[] = [
    { importType: MassImportType.ACCOUNT, typeTitle: 'ACCOUNT' },
    { importType: MassImportType.CONTACT, typeTitle: 'CONTACT' },
]

const ImportManagenmentPage = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [createMassImportVisible, setCreateMassImportVisible] = useState<boolean>(false);
    const [showImportDetails, setShowImportDetails] = useState<boolean>(false);
    const [importDetailsId, setImportDetailsId] = useState<string|null>(null)
    
    const { data, isError, isFetching, refetch } = useGetAllMassImportsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [startProcessing, { isLoading, error }] = useProcessImportMutation();

    useEffect(() => {
        const {id} = params;
        if(id){
            showImportDetailsBlock(id);
        }
    }, [])
    

    const onStartButtonClick = async (id: string) => {

        try {
            await startProcessing(id);
        }
        catch (ex) {
            console.log(ex);
        }

    }

    const onDetailsButtonClick = (id:string) => {
        console.log(id);
        showImportDetailsBlock(id);
        navigate(`/imports/${id}`)
    }

    const onDeleteButtonClick = (id: string) => {
        console.log('Delete', id)
    }

    const handleClose = () => {
        setShowImportDetails(false);
        navigate('/imports')
    }

    const showImportDetailsBlock = (id: string) => {
        setShowImportDetails(true);
        setImportDetailsId(id);
    }

    return (
        <InternalPage>
            <div className='title-block' style={{ backgroundImage: "url(/static/img/title-bg-2.png)" }}>
                <h1>
                    Update account/prescribers
                </h1>
                <div className="title-buttons">
                    <Button className='std-button' style={{ borderColor: '#ffffff', fontWeight: 'bold' }} onClick={() => setCreateMassImportVisible(true)}>Upload new set</Button>
                </div>
            </div>

            <h1>
                Import data management
            </h1>
            <div className='main-content'>
                <Card className='w-100 p-3'>

                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError 
                        && <MassImportList 
                                imports={data.imports}
                                isAlreadyProcessing={data.isAlreadyProcessing}
                                onStartButtonClick={onStartButtonClick}
                                onDetailsButtonClick={onDetailsButtonClick}
                                onDeleteButtonClick={onDeleteButtonClick}/>
                    }
                </Card>

            </div>
            <CreateMassImportModal
                show={createMassImportVisible}
                onHide={() => setCreateMassImportVisible(false)}
                onSuccess={() => setCreateMassImportVisible(false)}
                importTypes={importTypes}
                createImport={useCreateMassImportMutation}
                />


            <MassImportDetails show={showImportDetails} onHide={handleClose} importId={importDetailsId}/>
            
        </InternalPage>

    );
};

export default ImportManagenmentPage;