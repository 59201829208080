import React from 'react'
import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FlowStatus } from '../../models/enums/FlowStatus';
import { IIqviaDetail } from '../../models/IIqviaDetail';

type IqviaDetailsListItemProps = {
    detail: IIqviaDetail,
    onDetailEdit: (detail: IIqviaDetail) => void;
    onDetailDelete: (id: number) => void;
}

const IqviaDetailsListItem: React.FC<IqviaDetailsListItemProps> = ({detail, onDetailEdit, onDetailDelete}) => {

    return (
        <tr>
            <td>{detail.id}</td>
            <td>{detail.schema}</td>
            <td>{detail.table_prefix}</td>
            <td>{detail.table_creator}</td>
            <td>
            <Badge pill bg={detail.active ? 'success' : 'primary'}>
                    {detail.active ? 'Active' : 'Inactive'}
                </Badge>
            </td>
            <td>
                <Button variant="secondary" size="sm" className='me-1'>View</Button>
                <Button variant="secondary" size="sm" className='me-1' onClick={() => onDetailEdit(detail)}>Edit</Button>
                {/* <Button variant="danger" size="sm" onClick={()=>onDetailDelete(detail.id)} >Delete</Button> */}
            </td>
        </tr>
        
    )
}

export default IqviaDetailsListItem
