import React, { useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { IMassImport } from '../../models/MassImport/IMassImport';
import { useGetMassImportQuery } from '../../store/api/massImportApi';
import { Link } from "react-router-dom";

type IMassImportDetailsProps = {
    show: boolean,
    onHide: () => void,
    importId: string | null,
}

const MassImportDetails: React.FC<IMassImportDetailsProps> = ({ show, onHide, importId }) => {


    const { data, isFetching, isError } = useGetMassImportQuery(importId, {
        skip: importId == null
    });

    return (
        <Offcanvas show={show} placement={'end'} onHide={onHide} >
            {isFetching && <div>Loading...</div>}
            {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
            {data && !isFetching && !isError
                && <>
                    <Offcanvas.Header closeButton style={{borderBottom: 'solid 1px #DEE2E6'}}>
                        <Offcanvas.Title>{data.title}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <div className='details-point'>ID: {importId}</div>
                            </li>
                            <li className="list-group-item">
                                <div className='details-point'>Type: {data.type}</div>
                            </li>
                            <li className="list-group-item">
                                <div className='details-point'>Current status: {data.status}</div>
                            </li>
                            <li className="list-group-item">
                                <div className='details-point'>File name: {data.originalFileName}</div>
                            </li>
                            <li className="list-group-item">
                                <div className='details-point'>Created date: {data.created_at.toLocaleString()}</div>
                            </li>
                        </ul>
                        <div>
                            <Link to={'logs'} target="_blank">Import log</Link>
                        </div>
                        <div>
                            Description: {data.description}
                        </div>
                    </Offcanvas.Body>       
                </>
            }
        </Offcanvas>
    );
};

export default MassImportDetails;