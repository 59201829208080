import React, {useEffect, useMemo} from 'react'
import { Button, Form, FormControl, Modal, Spinner } from 'react-bootstrap'
import { createIqviaDetailValidationSchema } from '../../utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useCreateDetailMutation, useUpdateDetailMutation } from '../../store/api/iqviaDetailApi';
import { IIqviaDetail } from '../../models/IIqviaDetail';

type UpdateIqviaDetailProps = {
    detail: IIqviaDetail;
    show: boolean;
    onHide: () => void;
    onSuccess: () => void;
}

const defaultErrorMsg = 'Oops. Something went wrong. Please, try again later. ';

const UpdateIqviaDetail: React.FC<UpdateIqviaDetailProps> = ({detail, show, onHide, onSuccess}) => {

    const [updateDetail, {isLoading, error}] = useUpdateDetailMutation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IIqviaDetail>({
        defaultValues: useMemo(() => {
            return detail;
          }, [detail]),
        resolver: yupResolver(createIqviaDetailValidationSchema)
    });

    useEffect(() => {
        reset(detail);
      }, [detail]);

    const onSubmit = async (data: IIqviaDetail) => {

        // setShowAlert(null)
        console.log('data', data)

        try{
            const detailData = await updateDetail(data);
            console.log('Detail Data', detailData);
            
            if('error' in detailData){
                console.log(detailData.error);
                const err = detailData.error as any;
                const errorMsg = err.data?.message || defaultErrorMsg;
                // setShowAlert(errorMsg)
            }
            else{
                // navigate(STATISTIC_ROUTE)
                reset()
                onSuccess()
            }
        }
        catch(err){
            console.log('alert')
            // setShowAlert(defaultErrorMsg)
            console.log('error in catch: ', err);
        }
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Iqvia Detail
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>

                    <FormControl
                    {...register('schema')}
                    className={`mt-3 ${errors.schema ? 'is-invalid' : ''}`}
                    placeholder='Schema'
                    />
                    <div className="invalid-feedback align-self-start">{errors.schema?.message}</div>

                    <FormControl
                    {...register('table_prefix')}
                    className={`mt-3 ${errors.table_prefix ? 'is-invalid' : ''}`}
                    placeholder='Table prefix'
                    />
                    <div className="invalid-feedback align-self-start">{errors.table_prefix?.message}</div>

                    <FormControl
                    {...register('s3_folder')}
                    className={`mt-3 ${errors.s3_folder ? 'is-invalid' : ''}`}
                    placeholder='S3 folder'
                    />
                    <div className="invalid-feedback align-self-start">{errors.s3_folder?.message}</div>

                    <FormControl
                    {...register('destination_schema')}
                    className={`mt-3 ${errors.destination_schema ? 'is-invalid' : ''}`}
                    placeholder='Destination schema'
                    />
                    <div className="invalid-feedback align-self-start">{errors.destination_schema?.message}</div>

                    <FormControl
                    {...register('destination_table')}
                    className={`mt-3 ${errors.destination_table ? 'is-invalid' : ''}`}
                    placeholder='Destination table'
                    />
                    <div className="invalid-feedback align-self-start">{errors.destination_table?.message}</div>

                    <FormControl
                    {...register('table_creator')}
                    className={`mt-3 ${errors.table_creator ? 'is-invalid' : ''}`}
                    placeholder='Table creator'
                    />
                    <div className="invalid-feedback align-self-start">{errors.table_creator?.message}</div>

                    <Form.Group className="mb-3 mt-3">
                        <Form.Check
                            {...register('active')}
                            type='checkbox'
                            label={`Active`}
                        />
                        <Form.Check
                            {...register('create_original_table')}
                            type='checkbox'
                            label={`Create original table`}
                        />
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={onHide}>Close</Button>
                <Button variant='outline-success' onClick={handleSubmit(onSubmit)}>
                    {isLoading && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"/>}
                    <span className="sr-only">Update</span>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateIqviaDetail
