import { IFlow } from './../../models/IFlow';
import { api } from './api'
import { FlowResponse } from './types';

export const flowApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllFlows: build.query<IFlow[], void>({
            query: () =>  ({ url: '/flow' }),
        }),
        createFlow: build.mutation<FlowResponse, IFlow>({
            query: (flowInput: IFlow) => ({
              url: '/flow/create',
              method: 'POST',
              body: flowInput,
              credentials: 'include',
            })
          }),
          updateFlow: build.mutation<FlowResponse, IFlow>({
            query: (flowInput: IFlow) => ({
              url: '/flow/update',
              method: 'POST',
              body: flowInput,
              credentials: 'include',
            })
          }),
          deleteFlow: build.mutation<void, number>({
            query: (id: number) => ({
              url: `/flow/${id}`,
              method: 'DELETE',
              credentials: 'include',
            })
          }),
    })
})

export const {
    useGetAllFlowsQuery,
    useCreateFlowMutation,
    useUpdateFlowMutation,
    useDeleteFlowMutation,
} = flowApi