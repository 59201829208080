import React from 'react';
import Table from 'react-bootstrap/Table';
import { IUser } from '../../models/IUser';
import UsersListItem from './UsersListItem';

type UsersListProps = {
    users: IUser[];
    onUserEdit: (user: IUser) => void;
    onUserDelete: (user: IUser) => void;
}

const UsersList: React.FC<UsersListProps> = ({users, onUserEdit, onUserDelete}) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>#</th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => <UsersListItem key={user.id} user={user} onUserEdit={onUserEdit} onUserDelete={onUserDelete} />)}
            </tbody>
        </Table>
    );
};

export default UsersList;