import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import InternalPage from './InternalPage';
import CreateMassImportModal from '../components/modals/massImport/CreateMassImportModal';
import MassImportList from '../components/massImport/MassImportList';
import MassImportDetails from '../components/massImport/MassImportDetails';
import { useCreateSalesImportMutation, useDeleteSalesImportMutation, useGetAllSalesImportsQuery, useProcessSalesImportMutation } from '../store/api/salesImportApi';
import { IImportTypeOption } from '../models/MassImport/IImportTypeOption';
import { MassImportType } from '../models/MassImport/MassImportType';

const importTypes: IImportTypeOption[] = [
    { importType: MassImportType.SALES_MEDICOMART_WEEKLY, typeTitle: 'Medicomart Weekly Sales' },
]

const ImportSalesPage = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [createMassImportVisible, setCreateMassImportVisible] = useState<boolean>(false);
    const [showImportDetails, setShowImportDetails] = useState<boolean>(false);
    const [importDetailsId, setImportDetailsId] = useState<string|null>(null)
    
    const { data, isError, isFetching, refetch } = useGetAllSalesImportsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [startProcessing, { isLoading, error }] = useProcessSalesImportMutation();
    const [deleteImport, { isLoading: isDeleting, error: deletError }] = useDeleteSalesImportMutation()

    useEffect(() => {
        const {id} = params;
        if(id){
            showImportDetailsBlock(id);
        }
    }, [])
    

    const onStartButtonClick = async (id: string) => {

        try {
            await startProcessing(id);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const onDeleteButtonClick = async (id: string) => {
        
        try {
            await deleteImport(id);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const onDetailsButtonClick = (id:string) => {
        console.log(id);
        showImportDetailsBlock(id);
        navigate(`/sales-imports/${id}`)
    }

    const handleClose = () => {
        setShowImportDetails(false);
        navigate('/sales-imports')
    }

    const showImportDetailsBlock = (id: string) => {
        setShowImportDetails(true);
        setImportDetailsId(id);
    }

    return (
        <InternalPage>
            <div className='title-block' style={{backgroundImage: "url(/static/img/title-bg-1.png)"}}>
                <h1>
                    Import Sales
                </h1>
                <div className="title-buttons">
                    <Button className='std-button' style={{ borderColor: '#ffffff', fontWeight: 'bold' }} onClick={() => setCreateMassImportVisible(true)}>Upload new data</Button>
                </div>
            </div>
            
            <div className='main-content'>
                <Card className='w-100 p-3'>
                    

                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError 
                        && <MassImportList 
                                imports={data.imports}
                                isAlreadyProcessing={data.isAlreadyProcessing}
                                onStartButtonClick={onStartButtonClick}
                                onDetailsButtonClick={onDetailsButtonClick}
                                onDeleteButtonClick={onDeleteButtonClick}/>
                    }
                </Card>

            </div>
            <CreateMassImportModal
                show={createMassImportVisible}
                onHide={() => setCreateMassImportVisible(false)}
                onSuccess={() => setCreateMassImportVisible(false)}
                importTypes={importTypes}
                createImport={useCreateSalesImportMutation}/>

            <MassImportDetails show={showImportDetails} onHide={handleClose} importId={importDetailsId}/>
            
        </InternalPage>

    );
};

export default ImportSalesPage;