import React, { useState } from 'react';
import { Button, Form, FormControl, Spinner } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginFormValidationSchema } from '../utils/validators';
import { LoginRequest } from '../store/api/types';
import { useLoginMutation } from '../store/api/usersApi';
import { useNavigate } from 'react-router-dom';
import { STATISTIC_ROUTE } from '../utils/contsants';

const defaultErrorMsg = 'Oops. Something went wrong. Please, try again later. ';

const LoginForm = () => {

    const navigate = useNavigate();
    const [login, {isLoading, error}] = useLoginMutation();
    const [showAlert, setShowAlert] = useState<string|null>(null)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<LoginRequest>({
        resolver: yupResolver(loginFormValidationSchema)
    });

    const onSubmit = async (data: LoginRequest) => {

        setShowAlert(null)

        try{
            const userData = await login(data);
            console.log('user data', userData);
            
            if('error' in userData){
                console.log(userData.error);
                const err = userData.error as any;
                const errorMsg = err.data?.message || defaultErrorMsg;
                setShowAlert(errorMsg)
            }
            else{
                navigate(STATISTIC_ROUTE)
            }
        }
        catch(err){
            console.log('alert')
            setShowAlert(defaultErrorMsg)
            console.log('error in catch: ', err);
        }
        
    }

    return (
        <Form className='d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>
            
            {showAlert && <Alert style={{marginTop: '15px'}} variant={'danger'} onClose={() => setShowAlert(null)} dismissible>
                {showAlert}   
            </Alert>}

            <FormControl
                {...register('email')}
                className={`mt-3 ${errors.email ? 'is-invalid' : ''}`}
                placeholder='Email' />
                <div className="invalid-feedback align-self-start">{errors.email?.message}</div>

            <FormControl
                {...register('password')}
                className={`mt-3 ${errors.password ? 'is-invalid' : ''}`}
                type="password"
                placeholder='Password'/>
                <div className="invalid-feedback align-self-start">{errors.password?.message}</div>

            <Button type='submit' className='mt-3 align-self-end' variant={"outline-success"} disabled={isLoading}>
                {isLoading && <Spinner
                 as="span"
                 animation="border"
                size="sm"
                role="status"
                aria-hidden="true"/>}
                <span className="sr-only">Login</span>
            </Button>
        </Form>
    );
};

export default LoginForm;