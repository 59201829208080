import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import{logout,tokenReceived, userLoggedIn} from '../features/AuthSlice';
import { Mutex } from 'async-mutex'
import { RootState } from '../store'
import * as config from '../../config';
import { UserResponse } from './types';
import { useGetCurrentQuery } from './usersApi';

// create a new mutex
const mutex = new Mutex();

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).authReducer.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
    credentials: 'include'
  })
  
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 })
  
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {

  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {

    // checking whether the mutex is locked
    if(mutex.isLocked()){
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }

    const release = await mutex.acquire()
    try{
      // try to get a new token
      const refreshResult = await baseQuery('/user/refresh', api, extraOptions)
      // const currResult = await useGetCurrentQuery()
      if (refreshResult.data) {
        // store the new token
        api.dispatch(userLoggedIn(refreshResult.data as UserResponse))
        // retry the initial query
        result = await baseQuery(args, api, extraOptions)
      } else {
        api.dispatch(logout())
      }
    }
    finally{
      release()
    }

  }
  return result
}

  /**
   * Create a base API to inject endpoints into elsewhere.
   * Components using this API should import from the injected site,
   * in order to get the appropriate timport { UserResponse } from './../../services/usersApi';
ypes,
   * and to ensure that the file injecting the endpoints is loaded 
   */
  export const api = createApi({
    /**
     * `reducerPath` is optional and will not be required by most users.
     * This is useful if you have multiple API definitions,
     * e.g. where each has a different domain, with no interaction between endpoints.
     * Otherwise, a single API definition should be used in order to support tag invalidation,
     * among other features
     */
    // reducerPath: 'splitApi',

    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithReauth,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    // tagTypes: ['Time', 'Posts', 'Counter'],
    tagTypes: ['IqviaDetail', 'User', 'MassImport', 'Chargeback', 'SalesImport', 'CorrectedChargebackValue'],
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({}),
  })
  
  export const enhancedApi = api.enhanceEndpoints({
    endpoints: () => ({
      getPost: () => 'test',
    }),
  })