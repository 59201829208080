import { api } from './api'
import { IImportLog } from '../../models/IImportLog';

export const importLogApi = api.injectEndpoints({
    endpoints: (build) => ({
        getLogsByImportId: build.query<IImportLog[], string>({
            query: (importId: string) =>  ({ url: `/mass-import/${importId}/logs` })            
        })
    })
})

export const {
    useGetLogsByImportIdQuery,  
} = importLogApi