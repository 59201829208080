import React, {useEffect, useMemo, useState} from 'react'
import { Button, Form, FormControl, Modal, Spinner, Alert } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { createUserValidationSchema } from '../../../utils/validators';
import { useCreateUserMutation } from '../../../store/api/usersApi';
import { UserRole } from '../../../models/enums/UserRole';
import { SignUpRequest } from '../../../store/api/types';

type CreateUserModalProps = {
    // user: IUser;
    show: boolean;
    onHide: () => void;
    onSuccess: () => void;
}

const defaultErrorMsg = 'Oops. Something went wrong. Please, try again later. ';

const CreateUserModal: React.FC<CreateUserModalProps> = ({ show, onHide, onSuccess }) => {

    const [createUser, { isLoading, error }] = useCreateUserMutation()

    const [errorMsg, setErrorMsg] = useState<string|null>(null)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<SignUpRequest>({
        resolver: yupResolver(createUserValidationSchema)
    });

    const onModalHide = () => {
        setErrorMsg(null);
        onHide();
    }

    const onSubmit = async (data: SignUpRequest) => {

        // setShowAlert(null)
        console.log('Submit data', data)

        try{
            const userData = await createUser(data);
            console.log('User Data', userData);
            
            if('error' in userData){
                console.log(userData.error);
                const err = userData.error as any;
                const errorMsg = err.data?.message || defaultErrorMsg;
                setErrorMsg(errorMsg)
                // setShowAlert(errorMsg)
            }
            else{
                reset()
                onSuccess()
            }
        }
        catch(err){
            console.log('alert')
            // setShowAlert(defaultErrorMsg)
            console.log('error in catch: ', err);
        }
    }

    return (
        <Modal
            
            centered
            show={show}
            onHide={onModalHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create new user
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMsg && <Alert variant='danger' onClose={() => setErrorMsg(null)} dismissible>
                    {errorMsg}
                </Alert>}
                <Form className='d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>

                    <FormControl
                    {...register('email')}
                    className={`mt-3 ${errors.email ? 'is-invalid' : ''}`}
                    placeholder='Email'
                    />
                    <div className="invalid-feedback align-self-start">{errors.email?.message}</div>

                    <FormControl
                        {...register('password')}
                        className={`mt-3 ${errors.password ? 'is-invalid' : ''}`}
                        placeholder='Password'
                        type="password"
                    />
                    <div className="invalid-feedback align-self-start">{errors.password?.message}</div>

                    <FormControl
                        {...register('confirmPassword')}
                        className={`mt-3 ${errors.confirmPassword ? 'is-invalid' : ''}`}
                        placeholder='Confirm password'
                        type="password"
                    />
                    <div className="invalid-feedback align-self-start">{errors.confirmPassword?.message}</div>

                    <Form.Select
                        {...register('role')}
                        aria-label="Role select"
                        className={`mt-3 ${errors.role ? 'is-invalid' : ''}`}>
                        <option value="">Choose Role</option>
                        <option value={UserRole.USER}>USER</option>
                        <option value={UserRole.ADMIN}>ADMIN</option>
                    </Form.Select>
                    <div className="invalid-feedback align-self-start">{errors.role?.message}</div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={onHide}>Close</Button>
                <Button variant='outline-success' onClick={handleSubmit(onSubmit)}>
                    {isLoading && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"/>}
                    <span className="sr-only">Create</span>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateUserModal
