import React from 'react';
import {authRoutes, publicRoutes} from '../routes'
import * as routeConsts from '../utils/contsants'
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../hooks/userAuth';

const AppRouter = () => {

    const auth = useAuth();
    console.log('auth', auth);

    return (
        <Routes>

            {auth.user && authRoutes.map(({path, Component,roles}) => {
                if(roles == undefined){
                    return <Route key={path} path={path} element={<Component />} />
                }
                else if (auth.user && roles.indexOf(auth.user.role) >= 0){
                    return <Route key={path} path={path} element={<Component />} />
                }
            })}
            {publicRoutes.map(({path, Component}) => {
                return <Route key={path} path={path} element={<Component/>} />
            })}
            <Route path="*" element={<Navigate to={routeConsts.LOGIN_ROUTE}/>} />
        </Routes>
)
};

export default AppRouter;