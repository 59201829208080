import React, { useState } from 'react'
import { Button, Modal, Spinner, Alert } from 'react-bootstrap'
import { IUser } from '../../../models/IUser';
import { useDeleteUserMutation } from '../../../store/api/usersApi';

type DeleteUserModalProps = {
    user: IUser;
    show: boolean;
    onHide: () => void;
    onSuccess: () => void;
}

const defaultErrorMsg = 'Oops. Something went wrong. Please, try again later. ';

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ user, show, onHide, onSuccess }) => {

    const [errorMsg, setErrorMsg] = useState<string | null>(null)

    const [deleteUser, { isLoading, error }] = useDeleteUserMutation();

    const onDeleteClick = async () =>{
        const deleteResponse = await deleteUser(user.id);

        if ('error' in deleteResponse) {
            console.log(deleteResponse.error);
            const err = deleteResponse.error as any;
            const errorMsg = err.data?.message || defaultErrorMsg;
            setErrorMsg(errorMsg)
        }
        else {
            onSuccess()
        }
    }

    return (
        <Modal

            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMsg && <Alert variant='danger' onClose={() => setErrorMsg(null)} dismissible>
                    {errorMsg}
                </Alert>}
                
                Are you shure you want to delete {user.email}? This action cannot be undone!
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>Cancel</Button>
                <Button variant='danger' onClick={onDeleteClick}>
                    {isLoading && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />}
                    <span className="sr-only">Delete</span>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteUserModal
