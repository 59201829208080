import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import InternalPage from './InternalPage';
import { useGetLogsByImportIdQuery } from '../store/api/importLogApi';
import ImportLogList from '../components/importLog/ImportLogList';
import { useParams } from "react-router-dom";

const ImportLogsPage = () => {
       
    const { importId = ''} = useParams();    

    const { data, isError, isFetching, refetch } = useGetLogsByImportIdQuery(importId, {
        refetchOnMountOrArgChange: true,
    });
    
    return (
        <InternalPage>
            <h1>
                Import Logs
            </h1>
            <div className='main-content'>
                <Card className='w-100 p-3'>                    
                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError && <ImportLogList logs={data}/>}
                </Card>

            </div>            
        </InternalPage>

    );
};

export default ImportLogsPage;