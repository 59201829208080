import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIqviaDetail } from "../../models/IIqviaDetail"
import { fetchIqviaDetails } from "./ActionCreators";

interface IqviaDetailState {
    details: IIqviaDetail[];
    isLoading: boolean;
    error: string | null;
}

const initialState: IqviaDetailState = {
    details: [],
    isLoading: false,
    error: null,
}

export const iqviaDetailSlice = createSlice({
    initialState,
    name: 'iqviaDetails',
    reducers: {},
    extraReducers: {
        [fetchIqviaDetails.pending.type]: (state) => {
            state.isLoading = true;
            state.error = null
        },
        [fetchIqviaDetails.fulfilled.type]: (state, action: PayloadAction<IIqviaDetail[]>) => {
            state.isLoading = false;
            state.error = null;
            state.details = action.payload;
        },
        [fetchIqviaDetails.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.details = [];
            state.error = action.payload;
        },
    }
})

export default iqviaDetailSlice.reducer;