import React, { useState } from 'react';
import { useCreateUserMutation, useGetUsersQuery, useUpdateUserMutation } from '../store/api/usersApi';
import InternalPage from './InternalPage';
import { Button, Card } from 'react-bootstrap';
import UsersList from './../components/users/UsersList';
import { IUser } from './../models/IUser';
import UpdateUserModal from '../components/modals/user/UpdateUserModal';
import CreateUserModal from '../components/modals/user/CreateUserModal';
import DeleteUserModal from '../components/modals/user/DeleteUserModal';

const UsersPage = () => {

    const [createUserVisible, setCreateUserVisible] = useState(false);
    const [updateUserVisible, setUpdateUserVisible] = useState(false);
    const [deleteUserVisible, setDeleteUserVisible] = useState(false);

    const [userToEdit, setUserToEdit] = useState<IUser | null>(null)
    const [userToDelete, setUserToDelete] = useState<IUser | null>(null)

    const { data, isError, isFetching, refetch } = useGetUsersQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    // const [updateUser, { isLoading, error }] = useUpdateUserMutation();
    const [createUser, { isLoading, error }] = useCreateUserMutation();

    const onUserUpdate = (user: IUser) => {
        setUserToEdit(user);
        setUpdateUserVisible(true)
    }

    const onUserDelete = (user: IUser) => {
        setUserToDelete(user);
        setDeleteUserVisible(true)
    }

    const onUserUpdateSuccess = () => {
        setUpdateUserVisible(false)
        setCreateUserVisible(false)
    }

    return (
        <InternalPage>
                <h1>
                    Users
                </h1>
                <div className='main-content'>
                    <Card className='w-100 p-3'>
                        <div className="mb-2">
                            <Button variant="success" style={{ float: 'right' }} onClick={() => setCreateUserVisible(true)}>Create new</Button>
                        </div>

                        {isFetching && <div>Loading...</div>}
                        {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError && <UsersList users={data} onUserEdit={onUserUpdate} onUserDelete={onUserDelete} />}
                    </Card>

                </div>

                <CreateUserModal show={createUserVisible} onSuccess={onUserUpdateSuccess} onHide={() => setCreateUserVisible(false)} />
                {userToEdit && <UpdateUserModal show={updateUserVisible} onSuccess={onUserUpdateSuccess} onHide={() => setUpdateUserVisible(false)} user={userToEdit} />}
                {userToDelete && <DeleteUserModal user={userToDelete} show={deleteUserVisible} onHide={() => setDeleteUserVisible(false)} onSuccess={() => setDeleteUserVisible(false)}/>}
        </InternalPage>
        
    );

    
};

export default UsersPage;