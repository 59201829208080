export const LOGIN_ROUTE = '/login'
export const USERS_ROUTE = '/users'
export const IQVIA_DETAILS_ROUTE = '/iqvia-details'
export const STATISTIC_ROUTE = '/statistic'
export const FLOWS_ROUTE = '/flow'
export const IMPORTS_MANAGEMENT_ROUTE = '/imports'
export const IMPORT_DETAILS_ROUTE = '/imports/:id'
export const IMPORT_LOGS_ROUTE = '/imports/:importId/logs'
export const IMPORTS_CHARGEBACK_ROUTE = '/chargebacks'
export const IMPORT_CHARGEBACK_DETAIL_ROUTE = '/chargebacks/:id'
export const IMPORT_CHARGEBACK_LOGS_ROUTE = '/chargebacks/:importId/logs'
export const CHARGEBACK_STATISTIC_ROUTE = '/chargebacks/statistic'
export const IMPORTS_SALES_ROUTE = '/sales-imports'
export const IMPORTS_SALES_DETAIL_ROUTE = '/sales-imports/:id'
export const IMPORTS_SALES_LOGS_ROUTE = '/sales-imports/:importId/logs'

export const UPDATED_CHARGEBACK_VALUES_ROUTE = '/update-cb-values'


export const ROLES_ADMIN = 'ADMIN'
export const ROLES_USER = 'USER'