import React from 'react';
import { Badge, Button, OverlayTrigger, Tooltip, Form, Spinner } from 'react-bootstrap';
import { useAuth } from '../../hooks/userAuth';
import { IImportStatusOption } from '../../models/MassImport/IImportStatusOption';
import { IMassImport } from '../../models/MassImport/IMassImport';
import { MassImportStatus } from '../../models/MassImport/MassImportStatus';
import { useUpdateImportStatusMutation } from '../../store/api/massImportApi';
import { ROLES_ADMIN } from '../../utils/contsants';
import { formatDate } from '../../utils/functions';

type MassImportListItemProps = {
    importItem: IMassImport;
    isAlreadyProcessing: boolean;
    onStartButtonClick: (id: string) => void;
    onDetailsButtonClick: (id: string) => void;
    onDeleteButtonClick: (id: string) => void;
}


const MassImportListItem: React.FC<MassImportListItemProps> = ({
    importItem,
    isAlreadyProcessing,
    onStartButtonClick,
    onDetailsButtonClick,
    onDeleteButtonClick,
}) => {

    const importStatuses: IImportStatusOption[] = [
        { importStatus: MassImportStatus.COMPLETE, statusTitle: 'Complete' },
        { importStatus: MassImportStatus.PROCESSING, statusTitle: 'Processing' },
        { importStatus: MassImportStatus.UPLOADED, statusTitle: 'Uploaded' },
        { importStatus: MassImportStatus.FAILED, statusTitle: 'Failed' },
    ]

    const auth = useAuth();
    const [updateImportStatus, { isLoading, error }] = useUpdateImportStatusMutation();

    const onStatusUpdated = async (e: React.ChangeEvent<HTMLSelectElement>) =>{
        const newStatus = e.target.value;

        await updateImportStatus({
            id: importItem.id,
            status: newStatus as MassImportStatus
        })
    }

    const getBadgeVariant = (status: MassImportStatus) => {
        switch (status) {
            case MassImportStatus.COMPLETE:
                return "success"
            case MassImportStatus.PROCESSING:
                return "secondary"
            case MassImportStatus.UPLOADED:
                return "primary"
            case MassImportStatus.FAILED:
                return "danger"
            case MassImportStatus.DELETED:
                return "dark"
            default:
                return "primary"
        }
    }

    const getStartImportButton = () => {
        
        if (importItem.status == MassImportStatus.COMPLETE
            || importItem.status == MassImportStatus.PROCESSING){
                return null;
        }

        if(!isAlreadyProcessing){
            return <Button variant="success" size="sm" className='me-1 mt-1 table-btn' onClick={() => onStartButtonClick(importItem.id)}>
                {importItem.isLoading && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true" />}
                Start
            </Button>
        }
        else{
            return (
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">One of the imports is already processing</Tooltip>}
                    >
                    <span className="d-inline-block">
                        <Button variant="success" size="sm" className='me-1 mt-1 table-btn' disabled style={{ pointerEvents: 'none' }}>Start</Button>
                    </span>
                </OverlayTrigger>
            )
        }
    }

    return (
        <tr>
            <td style={{ maxWidth: '230px' }}>{importItem.title}</td>            
            <td style={{ fontSize: '14px' }}>{importItem.type}</td>
            <td>
                <Badge pill bg={getBadgeVariant(importItem.status)}>
                    {importItem.status}
                </Badge>
            </td>
            <td style={{maxWidth: '250px'}}>{importItem.originalFileName}</td>
            <td><div style={{whiteSpace: 'pre-wrap'}}>{formatDate(importItem.created_at)}</div></td>
            <td>                
                <Button variant="primary" size="sm" className='me-1 mt-1 table-btn' onClick={() => onDetailsButtonClick(importItem.id)}>Details</Button>
                {getStartImportButton()}
                <Button variant='danger' size="sm" className='me-1 nt-1 table-btn' onClick={() => onDeleteButtonClick(importItem.id)}>
                    {importItem.isDeleting && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />}
                    Delete
                </Button>
                <br/>
                {auth.user != null && auth.user.role === ROLES_ADMIN && 
                    <Form.Select
                        value={importItem.status}
                        aria-label="Status select"
                        className={`mt-3`}
                        onChange={onStatusUpdated}>
                        {importStatuses.map(st => <option key={st.importStatus} value={st.importStatus}>{st.statusTitle}</option>)}
                        {/* <option value={UserRole.USER}>USER</option>
                    <option value={UserRole.ADMIN}>ADMIN</option> */}
                    </Form.Select>
                }
                
            </td>
        </tr>
    );
};

export default MassImportListItem;