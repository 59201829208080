import React from 'react'
import Table from 'react-bootstrap/Table';
import { IIqviaDetail } from '../../models/IIqviaDetail';
import IqviaDetailsListItem from './IqviaDetailsListItem';

type IqviaDetailsListProps = {
    details: IIqviaDetail[];
    onDetailEdit: (detail: IIqviaDetail) => void;
    onDetailDelete: (id: number) => void;
}

const IqviaDetailsList: React.FC<IqviaDetailsListProps> = ({details, onDetailEdit, onDetailDelete}) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Schema</th>
                    <th>Table Prefix</th>
                    <th>Table Creator</th>
                    <th>Status</th>
                    <th>#</th>
                </tr>
            </thead>
            <tbody>
                {details.map(detail => <IqviaDetailsListItem key={detail.id} detail={detail} onDetailEdit={onDetailEdit} onDetailDelete={onDetailDelete} />)}
            </tbody>
        </Table>
        
    )
}

export default IqviaDetailsList
