import { PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { IUser } from '../../models/IUser';
import { createSlice } from '@reduxjs/toolkit';
import { usersApi } from '../api/usersApi';
import { RootState } from '../store';
import { UserResponse } from '../api/types';

interface IAuthState {
    user: IUser | null;
    token: string | null;
}

const initialState: IAuthState = {
    user: null,
    token: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => initialState,
        tokenReceived: (state, action: PayloadAction<string>) => {
          state.token = action.payload;
        },
        userLoggedIn: (state, action: PayloadAction<UserResponse>) => {
          console.log('payload', action.payload)
          localStorage.setItem('accessToken', action.payload.accessToken)
          state.token = action.payload.accessToken
          state.user = action.payload.user
        }
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
          isAnyOf (usersApi.endpoints.login.matchFulfilled, usersApi.endpoints.getCurrent.matchFulfilled),
          (state, { payload }) => {
            localStorage.setItem('accessToken', payload.accessToken)
            state.token = payload.accessToken
            state.user = payload.user
          }
        )
        .addMatcher(
          isAnyOf (usersApi.endpoints.logout.matchFulfilled),
          (state, {}) => {
            localStorage.removeItem('accessToken');
            return initialState;
          }
        )
      }

})

export const {logout, tokenReceived, userLoggedIn} = authSlice.actions;