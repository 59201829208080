import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InternalPage from './InternalPage';
import { useGetCommonStatisticQuery, useGetYearStatisticQuery } from '../store/api/chargebackApi';
import ChargebacksStatistic from '../components/statistic/ChargebacksStatistic';
import ChargebacksCommonStatistic from '../components/statistic/ChargebacksCommonStatistic';

const ChargebackStatisticPage = () => {

    const { data: data2020, isError: isError2020, isFetching: isFetching2020 } = useGetYearStatisticQuery(2020, {
        refetchOnMountOrArgChange: true,
    });

    const { data: data2021, isError: isError2021, isFetching: isFetching2021 } = useGetYearStatisticQuery(2021, {
        refetchOnMountOrArgChange: true,
    });

    const { data: data2022, isError: isError2022, isFetching: isFetching2022 } = useGetYearStatisticQuery(2022, {
        refetchOnMountOrArgChange: true,
    });

    const { data: data2023, isError: isError2023, isFetching: isFetching2023 } = useGetYearStatisticQuery(2023, {
        refetchOnMountOrArgChange: true,
    });

    const { data: commonStatistic, isError: isCommonStatisticError, isFetching: isFetchingCommonStatistic } = useGetCommonStatisticQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    return (
        <InternalPage>
            <div className='title-block' style={{backgroundImage: "url(/static/img/title-bg-1.png)"}}>
                <h1>
                    Chargebacks Total
                </h1>
            </div>
            
            <div className='main-content'>
                <Card className='w-100 p-3'>
                    <Tabs
                        defaultActiveKey="chargebacks_2022"
                        id="chargebacks_statistic_tabs"
                        className="mb-3"
                    >
                        <Tab eventKey="chargebacks_2020" title="2020">
                            {isFetching2020 && <div>Loading...</div>}
                            {!isFetching2020 && isError2020 && <div>Oops. Somethins goes wrong.</div>}
                            {data2020 && !isFetching2020 && !isError2020
                                && <ChargebacksStatistic statistic={data2020} />
                            }
                        </Tab>
                        <Tab eventKey="chargebacks_2021" title="2021">
                            {isFetching2021 && <div>Loading...</div>}
                            {!isFetching2021 && isError2021 && <div>Oops. Somethins goes wrong.</div>}
                            {data2021 && !isFetching2021 && !isError2021
                                && <ChargebacksStatistic statistic={data2021} />
                            }
                        </Tab>
                        <Tab eventKey="chargebacks_2022" title="2022">
                            {isFetching2022 && <div>Loading...</div>}
                            {!isFetching2022 && isError2022 && <div>Oops. Somethins goes wrong.</div>}
                            {data2022 && !isFetching2022 && !isError2022
                                && <ChargebacksStatistic statistic={data2022}/>
                            }
                        </Tab>
                        <Tab eventKey="chargebacks_2023" title="2023">
                            {isFetching2023 && <div>Loading...</div>}
                            {!isFetching2023 && isError2023 && <div>Oops. Somethins goes wrong.</div>}
                            {data2023 && !isFetching2023 && !isError2023
                                && <ChargebacksStatistic statistic={data2023} />
                            }
                        </Tab>
                        <Tab eventKey="chargebacks_2024" title="2024" disabled>
                            Statistic for 2024
                        </Tab>
                        <Tab eventKey="common_statistic" title="Common">
                            {isFetchingCommonStatistic && <div>Loading...</div>}
                            {!isFetchingCommonStatistic && isCommonStatisticError && <div>Oops. Somethins goes wrong.</div>}
                            {commonStatistic && !isFetchingCommonStatistic && !isCommonStatisticError
                                && <ChargebacksCommonStatistic fieldName='GPO Name' statistic={commonStatistic} />
                            }
                        </Tab>
                    </Tabs>
                </Card>

            </div>            
        </InternalPage>

    );
};

export default ChargebackStatisticPage;