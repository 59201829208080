import { string } from 'yup';
import { IMassImport } from '../../models/MassImport/IMassImport';
import { api } from './api'
import {
    CreateMassImportRequest,
    ProcessImportResponse,
    IMassImportListResponse,
    IServerResponse, 
    UpdateImportStatusRequest
} from './types';

export const massImportApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllMassImports: build.query<IMassImportListResponse, void>({
            query: () =>  ({ url: '/mass-import/all' }),
            providesTags: ['MassImport'],
        }),
        getMassImport: build.query<IMassImport, string|null>({
            query: (id: string) => ({ url: '/mass-import/'+id }),
        }),
        createMassImport: build.mutation<IMassImport, CreateMassImportRequest>({
            query: (createInput: CreateMassImportRequest) => {

                const formData = new FormData();
                formData.append('title', createInput.title);
                formData.append('description', createInput.description);
                formData.append('type', createInput.type);
                formData.append('updateFile', createInput.updateFile);
                formData.append('file', createInput.updateFile);

                return{
                    url: '/mass-import/create',
                    method: 'POST',
                    body: formData ,
                    credentials: 'include',
                    formData: true,
                }

            },
            invalidatesTags: ['MassImport'],
          }),
          
        processImport: build.mutation<ProcessImportResponse, string>({
            query: (id: string) => ({
                url: '/mass-import/process-import',
                method: 'POST',
                body: {id},
                credentials: 'include',
            }),
            invalidatesTags: ['MassImport'],
        }),

        updateImportStatus: build.mutation<IServerResponse, UpdateImportStatusRequest>({
            query: (body: UpdateImportStatusRequest) => ({
                url: '/mass-import/update-status',
                method: 'POST',
                body,
                credentials: 'include',
            }),
            invalidatesTags: ['MassImport', 'Chargeback', 'SalesImport'],
        }),
          // updateFlow: build.mutation<FlowResponse, IFlow>({
          //   query: (flowInput: IFlow) => ({
          //     url: '/flow/update',
          //     method: 'POST',
          //     body: flowInput,
          //     credentials: 'include',
          //   })
          // }),
          // deleteFlow: build.mutation<void, number>({
          //   query: (id: number) => ({
          //     url: `/flow/${id}`,
          //     method: 'DELETE',
          //     credentials: 'include',
          //   })
          // }),
    })
})

export const {
    useGetAllMassImportsQuery,
    useGetMassImportQuery,
    useCreateMassImportMutation,
    useProcessImportMutation,
    useUpdateImportStatusMutation,
    // useDeleteFlowMutation,
} = massImportApi