import React from 'react'
import { Button, Form, FormControl, Modal, Spinner } from 'react-bootstrap'
import { createFlowValidationSchema } from '../../utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IFlow } from './../../models/IFlow';
import { FlowType } from '../../models/enums/FlowType';
import { useCreateFlowMutation } from '../../store/api/flowApi';

type CreateFlowProps = {
    show: boolean,
    onHide: () => void;
    onSuccess: () => void;
}

const defaultErrorMsg = 'Oops. Something went wrong. Please, try again later. ';

const CreateFlow: React.FC<CreateFlowProps> = ({show, onHide, onSuccess}) => {

    const [createFlow, {isLoading, error}] = useCreateFlowMutation()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IFlow>({
        resolver: yupResolver(createFlowValidationSchema)
    });

    const onSubmit = async (data: IFlow) => {

        // setShowAlert(null)

        try{
            const flowData = await createFlow(data);
            console.log('flow data', flowData);
            
            if('error' in flowData){
                console.log(flowData.error);
                const err = flowData.error as any;
                const errorMsg = err.data?.message || defaultErrorMsg;
                // setShowAlert(errorMsg)
            }
            else{
                // navigate(STATISTIC_ROUTE)
                reset()
                onSuccess()
            }
        }
        catch(err){
            console.log('alert')
            // setShowAlert(defaultErrorMsg)
            console.log('error in catch: ', err);
        }
        
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Flow
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>

                <FormControl
                {...register('title')}
                className={`mt-3 ${errors.title ? 'is-invalid' : ''}`}
                placeholder='Title' />
                <div className="invalid-feedback align-self-start">{errors.title?.message}</div>

                <FormControl
                {...register('description')}
                className={`mt-3 ${errors.description ? 'is-invalid' : ''}`}
                placeholder='Description' 
                as="textarea"
                rows={3}/>
                <div className="invalid-feedback align-self-start">{errors.description?.message}</div>

                <Form.Select 
                    {...register('type')}
                    aria-label="Type select"
                    className={`mt-3 ${errors.type ? 'is-invalid' : ''}`}>
                    <option value= "">Choose Type</option>
                    <option value={FlowType.UPDATE_ACCOUNTS_IN_SALESFORCE}>Update Accounts in SF</option>
                    <option value={FlowType.UPDATE_CONTACTS_IN_SALESFORCE}>Update Contacts in SF</option>
                    <option value={FlowType.UPDATE_DATA_FROM_IQVIA}>Update data from IQVIA</option>
                </Form.Select>
                <div className="invalid-feedback align-self-start">{errors.type?.message}</div>

                <FormControl
                {...register('condition')}
                className={`mt-3 ${errors.condition ? 'is-invalid' : ''}`}
                placeholder='Condition'
                as="textarea"
                rows={3} />
                <div className="invalid-feedback align-self-start">{errors.condition?.message}</div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={onHide}>Close</Button>
                <Button variant='outline-success' onClick={handleSubmit(onSubmit)}>
                    {isLoading && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"/>}
                    <span className="sr-only">Add</span>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateFlow
